<template>
    <b-container>
        <b-row class="vh-100 text-center" align-v="center">
            <b-col v-if="!loading">
                <feather-icon icon="CheckCircleIcon" class="mb-1" size="55" stroke-width="3" />
                <h2 class="mb-1">Your email has been verified</h2>
                <b-button variant="primary" @click="$router.push({ name: 'login' })">
                    Continue
                </b-button>
            </b-col>
            <b-col v-else>
                <b-spinner label="Loading..."></b-spinner>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {
    BCol, BRow, BButton, BContainer, BSpinner
} from 'bootstrap-vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    name: 'EmailVerification',
    data() {
        return {
            url: null
        };
    },
    components: {
        BSpinner,
        BContainer,
        BCol,
        BRow,
        BButton
    },
    computed: {
        loading() {
            return this.$store.state.loading
        }
    },
    mounted() {
        if (!this.$route.query.url) {
            this.$router.push({ name: 'login' })
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Link Expired or Invalid",
                    icon: "CheckIcon",
                    variant: "danger",
                },
            });
            return
        }
        this.url = decodeURI(this.$route.query.url);
        this.verifyEmail();
    },
    methods: {
        verifyEmail() {
            this.$store
                .dispatch('verifyEmail', this.url)
                .then(() => { })
                .catch(() => {
                    this.$router.push({ name: 'login' })
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Link Expired or Invalid",
                            icon: "CheckIcon",
                            variant: "danger",
                        },
                    });
                });
        },
    },
}
</script>